import React from 'react';
import { useInView } from 'react-intersection-observer';

import './resumeitem.css';




function positionHook(Component) {
    return function WrappedComponent(props) {
        const {ref, inView} = useInView({threshold: 0.1, delay: 100});
        return (<Component {...props} divref={ref} inView={inView}></Component>)
    }
}


class ResumeItem extends React.Component {
       

    render() {

        let resumeItem_class = this.props.inView ? " resumeItem-animate" : "";

        return (
            <div ref={this.props.divref} className={"resumeItem" + resumeItem_class}>
                <a href={this.props.info.companyLink}><div className="companyLogo" style={{backgroundImage: "url(" + this.props.info.companyLogo + ")"}}></div></a>
                <div className="resumeItemInfo">
                    <a href={this.props.info.companyLink} className="company">{this.props.info.company}</a>
                    <div className="location">[{this.props.info.location}]</div>
                    <div className="positionWrapper">
                        <div className="position">{this.props.info.position}</div>
                        <div className="dates">{this.props.info.startDate + " - " + this.props.info.endDate}</div>    
                    </div>
                    <div className="summary">{this.props.info.summary}</div>
                    <hr className="divider"></hr>
                    <div className="descriptionSubtitle">Responsibilities: </div>
                    <ul className="description">
                        {
                            this.props.info.description.map((d, i) => <li key={i}>{d}</li>)
                        }
                    </ul>
                    
                    <div></div>
                </div>
            </div>
        )
        
    }
}

export default positionHook(ResumeItem);