import React from 'react';

import './education.css';

class Education extends React.Component {
       

    render() {
        return (
            <div className="education">
                <div className="educationBook backcover" style={{background: this.props.info.primaryColor}}></div>
                <div className="educationBookSpine" style={{background: this.props.info.primaryColor}}></div>

                {
                    [...Array(5)].map((x, i) => {
                        var cname = "educationBookPage";
                        var interiorContent = "";
                        var majorEmpty = (this.props.info.major === "")
                        var minorEmpty = (this.props.info.minor === "")
                        if (i === 4) {
                            cname += " educationBookPageContent";
                            interiorContent = (
                                <React.Fragment>
                                    <div className="educationPageHeader">
                                        <div className="schoolMedallionSmall">
                                            <div className="schoolLogoSmall" style={{backgroundImage: "url("+ this.props.info.schoolLogo + ")"}}></div>
                                        </div>
                                        <div className="educationPageHeaderText">
                                            <div className="schoolNameSmall">{this.props.info.schoolName}</div>
                                            <div className="schoolLocation">{this.props.info.location}</div>
                                            <div className="schoolDates">{this.props.info.startDate + " - " + this.props.info.endDate}</div>
                                        </div>
                                        
                                    </div>
                                    <hr className="bookPageDivider"></hr>
                                    {
                                        majorEmpty && minorEmpty ? "" : (
                                            <React.Fragment>
                                                <div className="educationPageStats">
                                                    {majorEmpty ? "" : <div className="majorSmall">Major: {this.props.info.major}</div>}
                                                    {minorEmpty ? "" : <div className="minorSmall">Minor: {this.props.info.minor}</div>}
                                                </div>
                                                <hr className="bookPageDivider"></hr>
                                            </React.Fragment>
                                        )
                                    }
                                    <div className="coursesText">Courses: </div>
                                    <div className="educationPageClasses">
                                        {
                                            this.props.info.courses.map ((c, i) => <div key={i} className="educationPageClass" style={{color: this.props.info.primaryColor}}>{c}</div>)
                                        }
                                    </div>
                                    <div className="affiliationsText">Affiliations: </div>
                                    <ul className="educationPageAffiliations">
                                        {
                                            this.props.info.affiliations.map ((a, i) => <li key={i} className="educationPageAffiliation">{a}</li>)
                                        }
                                    </ul>


                                </React.Fragment>

                            )
                        }
                            
                        return <div className={cname} key={i} style={{
                            right: -(3*(5-i+1)) + "px", 
                            top: -(3*(5-i+1)) + "px"
                            // transitionDelay: 0.2*i + "s"
                        }
                        }>{interiorContent}</div>
                    })
                }
                <div className="educationBook frontcover" style={{background: this.props.info.primaryColor}}>
                    <div className="frontcoverContent" style={{borderColor: this.props.info.secondaryColor}}>
                        <div className="schoolName">{this.props.info.schoolName}</div>
                        <div className="schoolMedallion">
                            <div className="schoolLogo" style={{backgroundImage: "url("+ this.props.info.schoolLogo +")"}}></div>
                        </div>
                        <div className="major">{this.props.info.major}</div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Education;