import React from 'react';
import * as THREE from 'three'
import { useInView } from 'react-intersection-observer';


import './general.css';
import './header.css';


function positionHook(Component) {
    return function WrappedComponent(props) {
        const {ref, inView} = useInView({threshold: 0, delay: 100});
        return (<Component {...props} divref={ref} inView={inView}></Component>)
    }
}



class TrailStop {
    static options = {
      nextStopAdvanceDistance: 10,
      nextStopDirectionSpreadFactor: 0.5,
      circleRadius: 10,
      minDuration: 0.5,
      maxDuration: 1,
      bezierCurveControlPointDistanceFactor: 5,
      stopRotationFactor: 1,
      numberOfCrossCurvePoints: 16,
      numberOfCrossCurves: 48,
      numberOfPointsOnCrossCurvePiece: 10
    };
    constructor(scene, previousStop) {
      this.scene_ = scene;
      this.previousStop_ = previousStop;
      this.bezierCurvePoints_ = [];
      if (this.previousStop_) {
        this.initWithPreviousStop();
      } else {
        this.initDefault();
      }
      this.addBezierCurvePoints();
      if (this.previousStop_) {
        this.calculateBezierCurves();
        this.addCrossCurves();
      }
      scene.add(this.obj_);
    }
  
    initDefault() {
      this.obj_ = new THREE.Object3D();
      this.obj_.position.set(0, 0, 0);
      this.obj_.updateMatrixWorld();
      this.startTime_ = 0;
      this.duration_ = 0;
    }
  
    initWithPreviousStop() {
      this.previousStop_.nextStop_ = this;
      this.obj_ = this.previousStop_.obj_.clone(false);
      this.obj_.position.copy(
        this.obj_.localToWorld(
          new THREE.Vector3(0, 0, -TrailStop.options.nextStopAdvanceDistance)
        )
      );
      this.obj_.updateMatrixWorld();
      this.startTime_ =
        this.previousStop_.startTime_ + this.previousStop_.duration_;
      this.duration_ = THREE.MathUtils.randFloat(
        TrailStop.options.minDuration,
        TrailStop.options.maxDuration
      );
  
      {
        const target = new THREE.Vector3(
          THREE.MathUtils.randFloatSpread(
            TrailStop.options.nextStopAdvanceDistance
          ),
          THREE.MathUtils.randFloatSpread(
            TrailStop.options.nextStopAdvanceDistance
          ),
          TrailStop.options.nextStopDirectionSpreadFactor *
            TrailStop.options.nextStopAdvanceDistance
        );
        this.obj_.up.applyAxisAngle(
          new THREE.Vector3(0, 0, 1),
          THREE.MathUtils.randFloatSpread(
            Math.PI * TrailStop.options.stopRotationFactor
          )
        );
        this.obj_.lookAt(this.obj_.localToWorld(target));
        this.obj_.updateMatrixWorld();
      }
    }
  
    addBezierCurvePointWithRandomControlPoint(p) {
      let controlPointOffset = new THREE.Vector3();
      if (this.previousStop_) {
        controlPointOffset.set(
          THREE.MathUtils.randFloatSpread(
            2 * TrailStop.options.bezierCurveControlPointDistanceFactor
          ),
          THREE.MathUtils.randFloatSpread(
            2 * TrailStop.options.bezierCurveControlPointDistanceFactor
          ),
          TrailStop.options.bezierCurveControlPointDistanceFactor
        );
      }
      this.bezierCurvePoints_.push([p, controlPointOffset.add(p)]);
    }
  
    addBezierCurvePoints() {
      this.addBezierCurvePointWithRandomControlPoint(new THREE.Vector3(0, 0, 0));
      this.addBezierCurvePointWithRandomControlPoint(
        new THREE.Vector3(0, TrailStop.options.circleRadius, 0)
      );
      for (let i = 0; i < TrailStop.options.numberOfCrossCurvePoints; ++i) {
        const angle =
          Math.PI *
          (1 +
            (2 * i + THREE.MathUtils.randFloatSpread(1)) /
              TrailStop.options.numberOfCrossCurvePoints);
        this.addBezierCurvePointWithRandomControlPoint(
          new THREE.Vector3(
            TrailStop.options.circleRadius * Math.cos(angle),
            TrailStop.options.circleRadius * Math.sin(angle),
            0
          )
        );
      }
    }
  
    calculateBezierCurves() {
      this.bezierCurves_ = [];
      for (let i = 0; i < this.bezierCurvePoints_.length; ++i) {
        const points = this.bezierCurvePoints_[i];
        const previousPoints = this.previousStop_.bezierCurvePoints_[i].map((p) =>
          this.obj_.worldToLocal(this.previousStop_.obj_.localToWorld(p.clone()))
        );
        previousPoints[1] = previousPoints[1]
          .negate()
          .addScaledVector(previousPoints[0], 2);
        this.bezierCurves_.push(
          new THREE.CubicBezierCurve3(
            previousPoints[0],
            previousPoints[1],
            points[1],
            points[0]
          )
        );
      }
    }
  
    addCrossCurves() {
      const allCurvePoints = [];
      for (let i = 0; i < TrailStop.options.numberOfCrossCurvePoints; ++i) {
        const points = this.bezierCurves_[
          this.bezierCurves_.length - 1 - i
        ].getPoints(TrailStop.options.numberOfCrossCurves);
        points.pop();
        allCurvePoints.push(points);
      }
      this.crossCurves_ = [];
      
        for (let i = 0; i < allCurvePoints.length; ++i) {
          const i1 = (i + 1) % allCurvePoints.length;
          const i2 = (i + 2) % allCurvePoints.length;
          for (let k = 0; k < allCurvePoints[i].length; ++k) {
            const curve = new THREE.QuadraticBezierCurve3(
              allCurvePoints[i][k]
                .clone()
                .add(allCurvePoints[i1][k])
                .multiplyScalar(0.5),
              allCurvePoints[i1][k],
              allCurvePoints[i2][k]
                .clone()
                .add(allCurvePoints[i1][k])
                .multiplyScalar(0.5)
            );
            const geometry = new THREE.BufferGeometry().setFromPoints(
              curve.getPoints(TrailStop.options.numberOfPointsOnCrossCurvePiece)
            );
            const material = new THREE.LineBasicMaterial({ color: 0xffffff });
            const line = new THREE.Line(geometry, material);
            this.obj_.add(line);
            this.crossCurves_.push({
              line: line,
              time: this.getTime(k / allCurvePoints[i].length)
            });
          }
        }
      
    }
  
    remove() {
      this.obj_.removeFromParent();
      if (this.crossCurves_) {
        this.crossCurves_.forEach(curve => {
          curve.line.geometry.dispose();
          curve.line.material.dispose();
        });
      }
  
      if (this.previousStop_) {
        this.previousStop_.nextStop_ = this.nextStop_;
      }
      if (this.nextStop_) {
        this.nextStop_.previousStop_ = this.previousStop_;
      }
    }
  
    getFinishTime() {
      return this.startTime_ + this.duration_;
    }
  
    getProgress(time) {
      return (time - this.startTime_) / this.duration_;
    }
  
    getTime(progress) {
      return this.startTime_ + progress * this.duration_;
    }
  
    updateCamera(ctx) {
      this.obj_.attach(ctx.camera);
  
      const progress = this.getProgress(ctx.timeElapsed);
  
      this.bezierCurves_[0].getPoint(progress, ctx.camera.position);
  
      ctx.camera.up.copy(
        this.obj_
          .localToWorld(this.bezierCurves_[1].getPoint(progress))
          .addScaledVector(
            this.obj_.localToWorld(ctx.camera.position.clone()),
            -1
          )
      );
      ctx.camera.lookAt(
        this.nextStop_.obj_.localToWorld(
          this.nextStop_.bezierCurves_[0].getPoint(progress)
        )
      );
    }
  
    updateColor(colorFunc) {
      if (!this.crossCurves_) {
        return;
      }
      // Update cross curve material
      for (const obj of this.crossCurves_) {
        obj.line.material.color.copy(colorFunc(obj.time));
      }
    }
  
    updateParticle(particle) {
      const progress = this.getProgress(particle.time);
      const point = this.bezierCurves_[
        this.bezierCurves_.length - 1 - particle.curveCurvePointIndex
      ].getPoint(progress);
      particle.obj.position.copy(this.obj_.localToWorld(point.clone()));
    }
}
  
class Trail {
    static options = {
      maxNumberStops: 8,
      maxVisibleStops: 2,
      indexOfCameraStop: 1,
      timeAdvanceStep: 0.003,
      numberOfParticles: 16,
      particleSpeedRange: [0.006, 0.012],
      maxParticleSpawnDelay: 0.5,
      particleSizeRange: [0.1, 0.5]
    };
  
    constructor(scene) {
      this.cameraBezierCurveProgress_ = 0;
      this.scene_ = scene;
  
      this.stops_ = [];
      for (let i = 0; i < Trail.options.maxNumberStops; ++i) {
        this.addNewStop();
      }
  
      this.particles_ = [];
      for (let i = 0; i < Trail.options.numberOfParticles; ++i) {
        const size = THREE.MathUtils.randFloat(
          Trail.options.particleSizeRange[0],
          Trail.options.particleSizeRange[1]
        );
        const cube = new THREE.Mesh(
          new THREE.BoxGeometry(size, size, size),
          new THREE.MeshBasicMaterial({ color: 0xffffff })
        );
        this.scene_.add(cube);
        this.particles_.push({ obj: cube });
      }
      for (const particle of this.particles_) {
        this.resetParticle(particle);
        particle.time += THREE.MathUtils.randFloatSpread(
          Trail.options.maxParticleSpawnDelay * Trail.options.numberOfParticles
        );
      }
  
      this.timeElapsed = this.stops_[Trail.options.indexOfCameraStop].startTime_;
    }
  
    resetParticle(particle) {
      particle.curveCurvePointIndex = THREE.MathUtils.randInt(
        0,
        TrailStop.options.numberOfCrossCurvePoints - 1
      );
  
      particle.time =
        this.getTotalDuration() +
        THREE.MathUtils.randFloat(0, Trail.options.maxParticleSpawnDelay);
      particle.speed = THREE.MathUtils.randFloat(
        Trail.options.particleSpeedRange[0],
        Trail.options.particleSpeedRange[1]
      );
    }
  
    addNewStop() {
      if (this.stops_.length === 0) {
        this.stops_.push(new TrailStop(this.scene_, null));
      } else {
        this.stops_.push(
          new TrailStop(this.scene_, this.stops_[this.stops_.length - 1])
        );
      }
      while (this.stops_.length > Trail.options.maxNumberStops) {
        const stop = this.stops_.shift();
        this.totalDurationOfFinishedStops += stop.duration_;
        stop.remove();
      }
    }
  
    getTotalDuration() {
      const lastStop = this.stops_[this.stops_.length - 1];
      return lastStop.startTime_ + lastStop.duration_;
    }
  
    getTimeRelativeToFirstStop() {
      return this.timeElapsed - this.stops_[0].startTime_;
    }
  
    colorFunction(time) {
      if (time < this.timeElapsed) {
        return new THREE.Color(1, 1, 1);
      }
      const maxDuration =
        (Trail.options.maxVisibleStops *
          (TrailStop.options.minDuration + TrailStop.options.maxDuration)) /
        2;
      let t = time - this.timeElapsed;
      if (t > maxDuration) {
        return new THREE.Color(0, 0, 0);
      }
      t = t / maxDuration;
      t = 1 - t * t;
      return new THREE.Color(t, t, t);
    }
  
    update(ctx) {
    //   const timeAdvanceStep = Trail.options.timeAdvanceStep;
      while (
        this.timeElapsed >
        this.stops_[Trail.options.indexOfCameraStop + 1].startTime_
      ) {
        this.addNewStop();
      }
      const cameraStop = this.stops_[Trail.options.indexOfCameraStop];
      cameraStop.updateCamera({
        camera: ctx.camera,
        timeElapsed: this.timeElapsed
      });
      for (const stop of this.stops_) {
        stop.updateColor((t) => this.colorFunction(t));
      }
      for (const particle of this.particles_) {
        particle.time -= particle.speed;
        if (particle.time > this.getTotalDuration()) {
          continue;
        }
        for (
          let curStop = this.stops_[this.stops_.length - 1];
          ;
          curStop = curStop.previousStop_
        ) {
          if (!curStop) {
            this.resetParticle(particle);
            break;
          }
          if (particle.time < curStop.startTime_) {
            continue;
          }
          particle.obj.material.color = this.colorFunction(particle.time);
          curStop.updateParticle(particle);
          break;
        }
      }
      this.timeElapsed += Trail.options.timeAdvanceStep;
    }
}






class Header extends React.Component {

    state = {
        scrolledDown: false
    }
    
    effect = () => {
        // Create new scene and camera
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 1000);
        camera.position.z = 96;

        // Create new renderer, add it to the canvas
        const canvas = document.getElementById('threeCanvas')
        const renderer = new THREE.WebGLRenderer({canvas, antialias: true});
        canvas.parentElement.appendChild(renderer.domElement);
        renderer.setClearColor(0x01051f, 1);

        // Create some ambient light, add it to the scene
        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        ambientLight.castShadow = true;
        scene.add(ambientLight);

        // Create a spotlight, add it to the scene
        const directionalLight = new THREE.SpotLight(0xffffff, 1);
        directionalLight.castShadow = true;
        directionalLight.position.set(0, 32, 64);
        scene.add(directionalLight);
        
        // Create a box with the meshnormal material, add the mesh to the scene 
        // const boxGeometry = new THREE.BoxGeometry(16, 16, 16);
        // const boxMaterial = new THREE.MeshStandardMaterial({wireframe: true});
        // const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
        // scene.add(boxMesh)

        // Create a trail
        const trail = new Trail(scene);

        // Add the STL
        // const loader = new STLLoader()
        // let carMesh;
        // loader.load(
        //     './car.stl',
        //     function (geometry) {
        //         const carMaterial = new THREE.MeshStandardMaterial();
        //         const carMesh = new THREE.Mesh(geometry, carMaterial);
                
        //         carMesh.scale.set(0.7, 0.7, 0.7);
        //         carMesh.rotateX(90);
        //         scene.add(carMesh);
        //     }
        // )

        // Function to resize the canvas to parent element
        function resizeCanvasToDisplaySize() {
            // look up the size the canvas is being displayed
            const width = canvas.clientWidth;
            const height = canvas.clientHeight;
          
            // you must pass false here or three.js sadly fights the browser
            renderer.setSize(width, height, false);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
          
            // update any render target sizes here
        }
        
        // Animate - at each frame...
        const animate = () => {
            // Resize the canvas to fit to the parent element
            resizeCanvasToDisplaySize();
            
            // // Rotate the box's x and y by a little bit
            // boxMesh.rotation.x += 0.01;
            // boxMesh.rotation.y += 0.01;

            // Update trail
            trail.update({
                camera: camera
            });
            
            // Render the scene, request an animation frame
            renderer.render(scene, camera);
            window.requestAnimationFrame(animate)
        }

        /* Uncomment to start! */
        animate();

    }
    scrollEvent = (e) => {
        
        var root = document.querySelector(':root');

        if (window.scrollY > 700) {
            this.setState({scrolledDown : true});
            root.style.setProperty("--header-color", "#01051f");
        }
        else {
            this.setState({scrolledDown : false});
            root.style.setProperty("--header-color", "#ffffff");
        }
    }

    componentDidMount() {
        // Set canvas size
        const canvas = document.getElementById('threeCanvas');
        canvas.style.width ='100%';
        canvas.style.height='100%';
        // ...then set the internal size to match
        canvas.width  = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        this.effect();

        // Set scroll listener
        window.addEventListener('scroll', this.scrollEvent);

    }
    componentDidUpdate() {
        // this.effect();
    }

    dispatchScrollTo = (elem) => {
        const customEvent = new CustomEvent('scrollTo', { index: elem });
        document.dispatchEvent(customEvent);
        // alert(elem);
    }

    

    render() {
        let navclass = "navbar" + (this.state.scrolledDown ? " scrolledDown" : "")
        let mainTitle_class = this.props.inView ? " mainTitle-animate" : "";
        let subtitle_class = this.props.inView ? " mainSubtitle-animate" : "";

        return (
            <div className="headerContainer">
                <div ref={this.props.divref} className="header">
                    <div className={navclass}>
                        <div className="navElement navHeader">Rushil Joshi</div>
                        <div className="navElement" onClick={() => this.dispatchScrollTo(0)}>Home</div>
                        <div className="navElement" onClick={() => this.dispatchScrollTo(1)}>About</div>
                        <div className="navElement" onClick={() => this.dispatchScrollTo(2)}>Experience</div>
                        <div className="navElement" onClick={() => this.dispatchScrollTo(3)}>Research</div>
                        <div className="navElement" onClick={() => this.dispatchScrollTo(4)}>Contact</div>
                        <div className="navElement navEnd"></div>
                    </div>
                    <div className="canvasContainer">
                        <canvas id="threeCanvas"/>
                    </div>
                    <div className={"mainTitle" + mainTitle_class}>Welcome</div>
                    <div className="subtitleContainer">
                        <div className={"mainSubtitle" + subtitle_class}>...to my profile.</div>
                    </div>
                </div>
            </div>
            
            
        )
        
    }
}

export default positionHook(Header);