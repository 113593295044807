import React from 'react';
import { useInView } from 'react-intersection-observer';

import ResumeItem from "./resumeitem.js";
import Project from "./project.js"
import Education from "./education.js"
import BusinessCard from "./businessCard.js"


import './general.css';
import './section.css';


function positionHook(Component) {
    return function WrappedComponent(props) {
        const {ref, inView} = useInView({threshold: 0, delay: 100});
        return (<Component {...props} divref={ref} inView={inView}></Component>)
    }
}

class Section extends React.Component {


    state = {
        cubeDegrees: 0,
        shownTrait: "ML Engineer",
        forwardFace: 0,
        anAddition: ""
    }

    listenToScroll = (e) => {
        
        let scrollMark = Math.floor(window.scrollY / 30);


        const traitList = ["ML Engineer", "Data Scientist", "Entrepreneur", "Developer", "Designer", "Hacker", "Innovator"];

        this.setState({cubeDegrees: scrollMark * -90});
        this.setState({shownTrait: traitList[scrollMark % traitList.length]})
        this.setState({forwardFace: scrollMark % 4})
        if ("AEIOUY".indexOf(traitList[scrollMark % traitList.length][0]) > -1) {
            this.setState({anAddition: "n"});
        }
        else {
            this.setState({anAddition: ""});
        }
        console.log(this.state.forwardFace, this.state.shownTrait);

    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
    

    getContent = (title) => {

        let subtitleClass = this.props.inView ? " sectionSubtitle-animate" : "";

        // Return "About Me" section
        if (title === "About Me") {

            let bday = new Date("11/20/2000");
            let today = new Date();
            let yearsOld = Math.floor((today - bday)/1000/60/60/24/365);

            let aboutMeImageOutline_class = this.props.inView ? " aboutMeImageOutline-animate" : "";
            let aboutMeImage_class = this.props.inView ? " aboutMeImage-animate" : "";
            let aboutMeTag_class = this.props.inView ? " aboutMeTag-animate" : "";

            let frontText = this.state.forwardFace === 0 ? this.state.shownTrait : "";
            let topText = this.state.forwardFace === 1 ? this.state.shownTrait : "";
            let backText = this.state.forwardFace === 2 ? this.state.shownTrait : "";
            let bottomText = this.state.forwardFace === 3 ? this.state.shownTrait : "";

            let nAppear_class = this.state.anAddition === "n" ? " nAppear-animate" : "";
            let aboutMeDescription_class = this.props.inView ? " aboutMeDescription-animate" : "";
          

            return (
                <div className="sectionContentWrapper">
                    
                    <div className="skillContainer">
                        {
                            this.props.data.skills.map((s, i) => {
                                let skill_text_scroll_class = this.props.inView ? " text-skill-large-animated" : "";
                                let skill_scroll_class = this.props.inView ? " skill-large-animated" : "";  
                                let transform_style = this.props.inView ? "translateX(" + s.offset[0] + "px) translateY(" + s.offset[1] + "px)" : "translateX(0px) translateY(0px)"
                                let randomDelay = this.props.inView? (0.2 + (Math.random() * 0.3)) : 0.1;
                                let colorGradient = "linear-gradient(to bottom right, " + s.color + " 0%, #FFFFFF 70%)";
                                
                                return (
                                    <div key={i} className={"skill-large" + skill_scroll_class} style={{transform: transform_style, transitionDelay: randomDelay+"s", background: colorGradient}}>
                                        <div className={"icon-skill-large " + s.class}></div>
                                        <div className={"text-skill-large" + skill_text_scroll_class}>{s.skill}</div>
                                    </div>
                                )
                                
                            })
                        }
                        
                    </div>
                    

                    <div className={"aboutMeImageOutline" + aboutMeImageOutline_class}>
                        <div className={"aboutMeImage" + aboutMeImage_class}></div>
                    </div>
                    <div className={"aboutMeTag" + aboutMeTag_class}>
                        <div className="nameTag">Rushil Joshi</div>
                        <div className="ageTag">Age: {yearsOld}</div>
                        <div className="locationTag">College Park, MD</div>
                    </div>
                    
                    <div className={"aboutMeDescription" + aboutMeDescription_class}>
                        <div className="normal descriptionSectionTitle">Welcome to my personal site!</div>
                        
                        <div className="infiniteScrollContainerOutlineRight"></div>
                        <div className="infiniteScrollContainer">    
                            <div className="normal infiniteScrollTitle">Simply put, I'm a<span className={"nAppear" + nAppear_class}>{this.state.anAddition}</span>:</div>
                            <div className="infiniteScroll">
                                <div className="cube .cube-animated" style={{transform: "translateZ(-100px) rotateX(" + this.state.cubeDegrees + "deg)"}}>
                                    <div className="face face-front">{frontText}</div>
                                    <div className="face face-back">{backText}</div>
                                    <div className="face face-right"></div>
                                    <div className="face face-left"></div>
                                    <div className="face face-top">{topText}</div>
                                    <div className="face face-bottom">{bottomText}</div>
                                </div>
                            </div>
                        </div>
                        <div className="infiniteScrollContainerOutlineLeft"></div>
                       
                        <div className="descriptionSection">
                            <div className="normal descriptionSectionTitle">I love:</div>
                            <div className="descriptionList">
                                <div className="descriptionListItem">
                                    <div className="itemTitle">Dogs</div>
                                    <div className="itemIcon dogs"></div>
                                </div>
                                <div className="descriptionListItem">
                                    <div className="itemTitle">Strong Coffee</div>
                                    <div className="itemIcon coffee"></div>
                                </div>
                                <div className="descriptionListItem">
                                    <div className="itemTitle">Building Cool Ideas</div>
                                    <div className="itemIcon building"></div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="descriptionSection">
                            <div className="normal descriptionSectionTitle">But most importantly, I am passionate about research into: </div>
                            <div className="descriptionList">
                                <div className="descriptionListItem">
                                    <div className="itemTitle">Machine Perception</div>
                                    <div className="itemIcon machinePerception"></div>
                                </div>
                                <div className="descriptionListItem">
                                    <div className="itemTitle">Computer Vision</div>
                                    <div className="itemIcon computerVision"></div>
                                </div>
                                <div className="descriptionListItem">
                                    <div className="itemTitle">Autonomous Driving</div>
                                    <div className="itemIcon autonomousDriving"></div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            )
        }
        // Return "Experience" section
        else if (title === "Experience") {
            return (
                <div className="sectionContentWrapper">
                    <div className="sectionSubtitleContainer"><div className={"sectionSubtitle" + subtitleClass}>Here's some of my previous work.</div></div>
                    <div className="resumeItemContainer">
                        {
                            this.props.data.resumeItems.map((r, i) => <ResumeItem key={i} info={r}/>)
                        }
                    </div>
                </div>
            )
        }
        // Return "Projects and Research" section
        else if (title === "Research and Projects") {
            return (
                <div className="sectionContentWrapper">
                    <div className="sectionSubtitleContainer"><div className={"sectionSubtitle" + subtitleClass}>My academic and entrepreneurial undertakings.</div></div>
                    <div className="projectContainer">
                        {
                            this.props.data.projects.map((p, i) => <Project key={i} ind={i} info={p}/>)
                        }
                    </div>
                </div>
            )
        }
        // Return "Education" section
        else if  (title === "Education") {
            return (
                <div className="sectionContentWrapper">
                    <div className="sectionSubtitleContainer"><div className={"sectionSubtitle" + subtitleClass}>School is cool. 😎 </div></div>
                    <div className="educationContainer">
                        {
                            this.props.data.schools.map((s, i) => <Education key={i} info={s} />)
                        }
                    </div>
                </div>
            )
        }
        // Return "Contact Me" section
        else if (title === "Contact Me") {
            return (
                <div className="sectionContentWrapper">
                    <div className="sectionSubtitleContainer"><div className={"sectionSubtitle" + subtitleClass}>Let's get in touch.</div></div>
                    <div className="businessCardContainer">
                        
                    </div>
                    <BusinessCard />
                </div>
            )
        }
        return "";
    }

    render() {
        
        let titleClass = this.props.inView ? " sectionTitle-animate" : "";
        return (
            <div className="section" style={{background: "linear-gradient(" + this.props.g1 + " 65%, " + this.props.g2 + " 100%)"}}>
                <h2 className={"sectionTitle" + titleClass}>{this.props.title}</h2>
                <div ref={this.props.divref} className="sectionContent">
                    {this.getContent(this.props.title)}
                </div>
            </div>
        )
        
    }
}

export default positionHook(Section);