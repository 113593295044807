import React from 'react';
import Header from './header.js';
import Section from './section.js';

import './App.css';

class App extends React.Component {
    
    state = {
        sections: [
            {title: "About Me", color: "#eaf0fd"},
            {title: "Experience", color: "#ffffff"},
            {title: "Research and Projects", color: "#fdf6ea"},
            {title: "Education", color: "#ffffff"},
            {title: "Contact Me", color: "#fdeaf1"},
        ],
        resumeItems: [
            {
                position: "Machine Learning Research Intern", 
                startDate: "May 2022", 
                endDate: "August 2022",
                location: "College Park, MD",
                company: "UMD Applied Research Lab for Intelligence & Security (ARLIS)",
                companyLogo: "https://media-exp1.licdn.com/dms/image/C4E0BAQFF3YWetTZ_Zw/company-logo_200_200/0/1603312847289?e=1671062400&v=beta&t=8ytZGPAyaYi2W4El9rvTPrYaNlu-k7jlJO60zYIjQkY",
                companyLink: "https://www.arlis.umd.edu/home",
                summary: "During this internship, I learned the foundations of state-of-the-art segmentation models, and also developed practical familiarity with the PyTorch framework and experience writing a conference paper.",
                description: [
                    "Applied transfer learning to state-of-the-art infrared image segmentation models to perform weakly-supervised image detection in noise-heavy maritime environments.",
                ],
                tags: ["OpenCV", "Angular", "Ionic", "Swift", "IOS Development", "Web Development", "Javascript"]
            },
            {
                position: "Software Engineering Intern", 
                startDate: "May 2021", 
                endDate: "September 2022",
                location: "Stillwater, MN",
                company: "Enhanced Information Solutions (EIS)",
                companyLogo: "https://media-exp1.licdn.com/dms/image/C4E0BAQFHDmBbmdPMxA/company-logo_200_200/0/1603131005904?e=1671062400&v=beta&t=TBVOuW7ryngV8mVBdflCm0IOWuTmBafwqSu9uHxjEII",
                companyLink: "https://eisinc.com/",
                summary: "This internship helped me learn the fundamentals of cross-platform software development (frameworks, best practices, integration with machine learning models) through Angular 2 and Ionic Native.",
                description: [
                    "Developed an intelligent scanning IOS application, built with Angular 2 and Ionic Native primarily for the iPad to scan pharmaceutical machine readouts.",
                    "Used OpenCV and PyTorch to detect, flatten, and OCR document fields in real-time, and then classify them by their structure using unsupervised machine learning."
                ],
                tags: ["OpenCV", "Angular", "Ionic", "Swift", "IOS Development", "Web Development", "Javascript"]
            },
            {
                position: "Machine Learning Intern", 
                startDate: "June 2018", 
                endDate: "August 2018",
                location: "Ashburn, VA",
                company: "Novateur Research Solutions",
                companyLogo: "https://media-exp1.licdn.com/dms/image/C4E0BAQFcIqeNfm6cEg/company-logo_200_200/0/1535489220646?e=1671062400&v=beta&t=w0XvEsvVz1jYmdw0esGeivhokwh6jABqkObqaQ6HQVA",
                companyLink: "https://novateur.ai/",
                summary: "This was my first professional experience with machine learning research, in my junior year of high school. I learned how to work with convolutional neural networks, and how to apply state-of-the-art CNN models to computer vision tasks.",
                description: [
                    "Developed and architected a convolutional LSTM network to detect termite infestations among flocks by identifying preening behavior in video footage of chickens, synthesized research in a presentation/paper.",
                    "Implemented RANSAC feature-based image stitching algorithms to increase the efficiency of tracking models in existing research."
                ],
                tags: ["Keras", "Python", "OpenCV", "Machine Learning", "Computer Vision"]
            }
        ],
        projects: [
            {
                title: "Mindgrasp.ai",
                subtitle: "Automated Note Generation using Large Language Models",
                startDate: "September 2020",
                endDate: "May 2022",
                description: [
                    "Mindgrasp is an all-in-one tool tool that converts any uploaded course material into well-written notes, using the power of large language models. ",
                    "This project-turned-venture started out as a hackathon project at JHacks UMD in 2020. Initially built as a question answering tool using the BERT language transformer, we decided to expand its functionality and release it as a SaaS web application for student use after winning first place at the hackathon and receiving exceptional student and faculty feedback.",
                    "During our development phase, I became familiar with the regime of large language transformers, and became involved with some of the advances made in question answering, summarization, entity recognition, and intent classification, all using custom GPT-2-like architecture and data. In addition, I implemented the vision transformer (ViT) architecture to perform document field segmentation, along with optical character recognition.",
                    "We were accepted into the Mokhtarzada Hatchery's 2021 cohort, as part of a hands-on accelerator with direct mentorship from the three brothers and co-founders of TrueBill and Webs.com",
                    "Although my journey at Mindgrasp has ended as I return to academia, I learned an incredible amount about deep learning, cloud computing, and entrepreneurship in general."
                ],
                images: [
                    "./mindgrasp.png"
                ]
            },
            {
                title: "Chariot",
                subtitle: "Open-Source Autonomous Driving",
                startDate: "September 2021",
                endDate: "Present",
                description: [
                    "The goal of this project was originally for me to become familiar with the math, theory, and state-of-the-art methods of autonomous driving. The first milestone of the project was to successfully detect pedestrians, stop signs, road lines, curbs, and other vehicles on the road, at a reasonable framerate on a Jetson Nano. I used an Intel L515 LIDAR depth camera, using only the RGB data provided." ,
                    "The second milestone was to introduce depth data to perform object detection. This was much harder, but using simulation data from the CARLA autonomous driving simulator for transfer learning allowed for my detection network to learn much faster and generalize robustly to my real data.",
                    "The third milestone aimed to actually control my 2021 Honda Insight via CAN signal, using Comma.ai's Panda module. Using a bluetooth adapter on the Jetson, I was able to interface with the car's steering system with a Wii remote.",
                    "The current milestone's goal is very basic - to reverse straight into a parking spot. This is proving to be a much harder task than I expected :) See https://www.rushiljoshi/blog/chariot to follow along with my progress!"
                ],
                images: [
                    "./chariot.jpeg"
                ]
            },
            {
                title: "OpenBacktest",
                subtitle: "Open-Source Backtesting and Algorithmic Trading",
                startDate: "August 2021",
                endDate: "Present",
                description: [
                    "OpenBacktest is a python framework for backtesting trading strategies over large-scale market data. It includes a fully-configurable Backtest class, an overridable Strategy class, and even a Session class for paper/live trading through Alpaca and other algorithmic brokerages. You can create a simple strategy and backtest in less than 20 lines of code, using preconfigured technical indicators.",
                    "The stable build is still currently in progress, but a basic version of the package with textual metrics is available on PyPi. ",
                ],
                images: [
                    "./openbacktest.jpeg"
                ]
            }
        ],
        schools: [
            {
                schoolName: "Thomas Jefferson High School for Science and Technology",
                schoolLogo: "./tjhsstLogo.png",
                location: "Alexandria, VA",
                major: "",
                minor: "",
                startDate: "September 2015",
                endDate: "May 2019",
                courses: ["Artificial Intelligence", "Parallel Computing", "A.P. Computer Science", "Robotics", "Computer Vision"],
                affiliations: ["USA Computing Olympiad", "Machine Learning Society"],
                primaryColor: "#1A2C56",
                secondaryColor: "#cc0000"
            },
            {
                schoolName: "University of Maryland - College Park",
                schoolLogo: "./umdLogo.png",
                location: "College Park, MD",
                major: "B.S. Computer Science",
                minor: "Technology Entrepreneurship",
                startDate: "August 2019",
                endDate: "May 2022",
                courses: ["Intro to Machine Learning", "Intro to Deep Learning", "Data Structures", "IOS Development", "Computer Vision"],
                affiliations: ["Autonomous Micro Aerial Vehicle Team (AMAV)", "Mokhtarzada Hatchery (2022)"],
                primaryColor: "#cc0000",
                secondaryColor: "#ffd619"
            },
            {
                schoolName: "University of Maryland - College Park",
                schoolLogo: "./umdLogo.png",
                location: "College Park, MD",
                major: "M.S. Computer Science",
                minor: "",
                startDate: "September 2022",
                endDate: "May 2023",
                courses: ["Robotics", "Foundations of Deep Learning", "Computational Imaging"],
                affiliations: ["Autonomous Micro Aerial Vehicle (AMAV) Team", "Smith Investment Fund"],
                primaryColor: "#990000",
                secondaryColor: "#ffd619"
            }
            // {
            //     schoolName: "University of Maryland - College park",
            //     schoolLogo: "https://upload.wikimedia.org/wikipedia/en/thumb/3/3e/University_of_Maryland_seal.svg/1200px-University_of_Maryland_seal.svg.png",
            //     location: "College Park, MD",
            //     major: "Ph.D. Computer Science",
            //     minor: "Technology Entrepreneurship",
            //     startDate: "August 2019",
            //     endDate: "May 2022",
            //     courses: ["Artificial Intelligence", "Parallel Computing", "A.P. Computer Science", "Robotics", "Mobile/WebDesign"],
            //     affiliations: ["USA Computing Olympiad", "Machine Learning Society"],
            //     primaryColor: "#770000",
            //     secondaryColor: "ffd619"
            // }
        ],
        skills: [
            {skill: "Python", class: "icon-skill-python", color: "#8295ff", offset: [-300, -120], type: "major"},
            {skill: "PyTorch", class: "icon-skill-pytorch", color: "#fb6d57", offset: [240, -120], type: "major"},
            {skill: "TensorFlow", class: "icon-skill-tensorflow", color: "#faa949", offset: [320, -15], type: "major"},
            {skill: "Pandas", class: "icon-skill-pandas", color: "#df81b7", offset: [-400, 30], type: "major"},
            {skill: "Photoshop", class: "icon-skill-photoshop", color: "#001733", offset: [-550, -100], type: "major"},
            {skill: "Excel", class: "icon-skill-excel", color: "#58e7a8", offset: [500, -100], type: "major"},
            {skill: "MySQL", class: "icon-skill-mysql", color: "#67a0c8", offset: [200, 90], type: "major"},
        ]

    }

    render() {
        return (
            // Main app class
            <div className="app">
                {/* Site header */}
                <Header />
                
                {/* Each section */}
                {
                    this.state.sections.map(
                        (s, i) => {
                            var g2 = "#ffffff";
                            if (i < this.state.sections.length - 1)
                                g2 = this.state.sections[i+1].color;
                            return <Section key={i} title={s.title} g1={s.color} g2={g2} data={this.state}/>
                        }
                    )
                }

                {/* Site Footer */}



            </div>
        )
        
    }
}

export default App;
