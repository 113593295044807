import React from 'react';
import { useInView } from 'react-intersection-observer';

import './project.css';



function positionHook(Component) {
    return function WrappedComponent(props) {
        const {ref, inView} = useInView({threshold: 0.1});
        return (<Component {...props} divref={ref} inView={inView}></Component>)
    }
}

class Project extends React.Component {
    
    state = {
        expanded: false
    }

    readMoreClicked = () => {
        this.setState({expanded: !this.state.expanded});
    }

    render() {

        let project_class = (this.props.ind % 2 === 0 ? " right" : " left") + (this.props.inView ? " project-animate" : "") + (this.state.expanded ? " project-visible" : "");
        let projectContent_class = this.state.expanded ? " projectContent-animate" : "";
        let projectContentContainer_class = this.state.expanded ? " projectContentContainer-animate" : "";

        return (
            <div className={"project" + project_class} ref={this.props.divref}>
                <div className="projectImageWindowSqueeze">
                    <div className={"projectImageWindowOutline " + (this.props.ind % 2 === 0 ? "righty" : "lefty")}>
                        <div className="projectImageWindow">
                            <div className="projectImage" style={{"backgroundImage": "url("+this.props.info.images[0]+")"}}></div>
                        </div>
                    </div>
                </div>
                
                <div className={"projectContentContainer" + projectContentContainer_class}>
                    <div className={"projectContent" + projectContent_class}>
                        
                        <div className="title">{this.props.info.title}</div>
                        <span className="subtitle">{this.props.info.subtitle}</span>
                        <div className="subtitleWrapper">
                            <div className="dates">{this.props.info.startDate + " - " + this.props.info.endDate}</div>
                        </div>
                        <div className="description">
                            {
                                this.props.info.description.map((d, i) => <p key={i}>{d}</p>)
                            }
                        </div>
                    </div>
                    <div className="showMore" onClick={this.readMoreClicked}>{this.state.expanded ? "Read Less..." : "Read More..."}</div>
                </div>
                
            </div>
        )
        
    }
}

export default positionHook(Project);