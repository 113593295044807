import React from 'react';
import { useInView } from 'react-intersection-observer';

import './businessCard.css';


function positionHook(Component) {
    return function WrappedComponent(props) {
        const {ref, inView} = useInView({threshold: 0, delay: 200});
        return (<Component {...props} divref={ref} inView={inView}></Component>)
    }
}

class BusinessCard extends React.Component {

    render() {

        let card_class = this.props.inView ? " card-animate" : "";

        return (
            <button ref={this.props.divref} className={"card button" + card_class}>
                <div className="row">
                    <p><span className="big">R</span>ushil <span className="big">Joshi</span></p>
                    {/* <p><span className="big">V</span>ice <span className="big">P</span>resident</p> */}
                </div>
                <div className="socials">
                    <div className="socialItem">
                        <div className="socialItemTitle">Email</div>
                        <div className="socialItemLogo social-email"></div>    
                    </div>
                    <div className="socialItem">
                        <div className="socialItemTitle">LinkedIn</div>
                        <div className="socialItemLogo social-linkedin"></div>    
                    </div>
                    <div className="socialItem">
                        <div className="socialItemTitle">GitHub</div>
                        <div className="socialItemLogo social-github"></div>    
                    </div>
                </div>
                <div className="row">
                    <p>
                        <span className="med">C</span><span className="small">ollege </span>
                        <span className="med">P</span><span className="small">ark, </span>
                        <span className="med">MD 20740</span>
                    </p>
                </div>
            </button>
        )
    }
}

export default positionHook(BusinessCard);

